import React from "react";
import AllRoutes from "./routes/Routes";
import { useAnalytics } from "./hooks/useAnalytics";

// Themes
// For Icons
import "./assets/scss/icons.scss";

// For Default import Default.scss
import "./assets/scss/config/default/app.scss";
import "./assets/scss/config/default/bootstrap.scss";
import { InitializeGoogleAnalytics } from "./services/google_analytics";

const App = () => {
  // Initialize analytics
  // useAnalytics();
  InitializeGoogleAnalytics();
  return (
    <>
      <React.Fragment>
        <AllRoutes />
      </React.Fragment>
    </>
  );
};

export default App;
