import ReactGA4 from "react-ga4";

const InitializeGoogleAnalytics = (): void => {
  const tracking_id = process.env.GOOGLE_ANALYTICS_ID || 'G-JWXXRVDRYT';
  ReactGA4.initialize(tracking_id);
  console.log("GA INITIALIZED");
};

const TrackGoogleAnalyticsEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
): void => {
  console.log("GA event = ", "category :", category, ":", "action :", action);

  ReactGA4.event({
    category: category,
    action: action,
    label: label,
    value: value,
  });
};

// export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };